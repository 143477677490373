<!-- =========================================================================================
    File Name: bookingCreate.vue
    Description: bookingCreate Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <form data-vv-scope="form-1">
      <vx-card
        :title="$t(resources.Create.i18n) || resources.Create.name"
        class="mb-4"
      >
        <div class="vx-row">
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                $t(resources.DistributorID.i18n) || resources.DistributorID.name
              }}</label>
              <v-select
                id="iboCode"
                @keyup.native="getIboByText()"
                v-model="iboCode"
                :clearable="true"
                :options="iboList"
                :reduce="item => item.itaCode"
                label="fullName"
                name="iboCode"
                v-validate="'required'"
                v-on:input="getCustomers(`${iboCode}`)"
              />
            </div>
            <span class="text-danger text-sm">{{
              errors.first("form-1.iboCode")
            }}</span>
          </div>
          <div class="vx-col md:w-1/4 w-full">
            <label class="vs-input--label">{{
              $t(resources.Customer.i18n) || resources.Customer.name
            }}</label>
            <v-select
              v-model="booking.contactId"
              :clearable="false"
              :options="customers"
              :reduce="customer => customer.id"
              label="fullName"
              v-on:input="getContact(`${booking.contactId}`)"
              :hint="`${booking.contactId}`"
              name="customer"
              v-validate="'required'"
            />
            <span class="text-danger text-sm">{{
              errors.first("form-1.customer")
            }}</span>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-4">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-mail"
                disabled
                :label="
                  `${$t(resources.Email.i18n) || resources.Email.name} - ${$t(
                    resources.Contact.i18n
                  ) || resources.Contact.name}`
                "
                v-model="emailUser"
              />
            </div>
          </div>
          <div class="vx-col w-full md:w-1/4 mb-2">
            <label class="vs-input--label">{{
              $t(resources.CountryOperation.i18n) ||
                resources.CountryOperation.name
            }}</label>
            <v-select
              v-model="countryOperation"
              :clearable="false"
              :options="countryOption"
              :reduce="data => data.countryCode"
              label="display"
              scrollable
              name="country"
              v-validate="'required'"
            >
              <template slot="option" slot-scope="option">
                <img :src="option.iconUrl" class="h-6 w-6" />
                {{ option.display }}
              </template>
            </v-select>
            <span class="text-danger text-sm">{{
              errors.first("form-1.country")
            }}</span>
          </div>
        </div>
      </vx-card>

      <vx-card
        :title="
          $t(resources.ContactFormTitle.i18n) || resources.ContactFormTitle.name
        "
        class="mb-4"
      >
        <div class="vx-row">
          <div class="vx-col md:w-1/4 w-full">
            <vs-input
              v-validate="'required|min:3|alpha_spaces'"
              :label="
                $t(resources.ContactName.i18n) || resources.ContactName.name
              "
              name="emergencyName"
              v-model="booking.emergencyName"
              class="w-full mb-1"
              icon-pack="feather"
              icon="icon-user"
              maxlength="100"
            />
            <span class="text-danger text-sm">{{
              errors.first("form-1.emergencyName")
            }}</span>
          </div>
          <div class="vx-col md:w-1/4 w-full">
            <vs-input
              v-validate="'required|email'"
              :label="$t(resources.Email.i18n) || resources.Email.name"
              name="emailVoucher"
              v-model="booking.emailVoucher"
              class="w-full mb-1"
              icon-pack="feather"
              icon="icon-mail"
              maxlength="150"
            />
            <span class="text-danger text-sm">{{
              errors.first("form-1.emailVoucher")
            }}</span>
          </div>
          <div class="vx-col md:w-1/6 w-full">
            <label class="vs-input--label">{{
              $t(resources.TelephoneType.i18n) || resources.TelephoneType.name
            }}</label>
            <v-select
              v-model="booking.emergencyPhoneType"
              :clearable="false"
              :options="phoneTypes"
              :reduce="x => x.id"
              label="type"
              name="phoneType"
              v-validate="'required'"
            />
            <span class="text-danger text-sm">{{
              errors.first("form-1.phoneTypes")
            }}</span>
            <span class="text-danger text-sm">{{
              errors.first("form-1.phoneType")
            }}</span>
          </div>
          <div class="vx-col md:w-1/6 w-full">
            <label class="vs-input--label">{{
              $t(resources.Indicative.i18n) || resources.Indicative.name
            }}</label>
            <v-select
              v-model="booking.emergencyCountryCode"
              :clearable="false"
              :options="countryList"
              :reduce="country => country.id"
              label="country"
              name="emergencyCountryCode"
              v-validate="'required'"
            />
            <span class="text-danger text-sm">{{
              errors.first("form-1.emergencyCountryCode")
            }}</span>
          </div>
          <div class="vx-col md:w-1/6 w-full">
            <vs-input
              v-validate="'required|min:8'"
              :label="$t(resources.Number.i18n) || resources.Number.name"
              name="emergencyPhone"
              v-model="booking.emergencyPhone"
              class="w-full"
              icon-pack="feather"
              icon="icon-hash"
              maxlength="20"
            />
            <span class="text-danger text-sm">{{
              errors.first("form-1.emergencyPhone")
            }}</span>
          </div>
        </div>
      </vx-card>

      <vx-card
        :title="
          $t(resources.BookingDetail.i18n) || resources.BookingDetail.name
        "
        class="mb-4"
      >
        <div class="vx-row">
          <div class="vx-col md:w-1/4 w-full mb-2">
            <label class="vs-input--label">{{
              $t(resources.ProductType.i18n) || resources.ProductType.name
            }}</label>
            <v-select
              v-model="bookingDetail.productId"
              :clearable="true"
              :options="productType"
              :reduce="product => product.id"
              label="name"
              v-on:input="getItineraryByProduct(`${bookingDetail.productId}`)"
              :hint="`${bookingDetail.productId}`"
              name="product"
              v-validate="'required'"
            />
            <span class="text-danger text-sm">{{
              errors.first("form-1.product")
            }}</span>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <label class="vs-input--label">{{
              $t(resources.Currency.i18n) || resources.Currency.name
            }}</label>
            <v-select
              v-model="bookingDetail.currencyCode"
              :clearable="true"
              :options="currencyType"
              :reduce="x => x.code"
              label="display"
              name="currency"
              v-validate="'required'"
            />
            <span class="text-danger text-sm">{{
              errors.first("form-1.currency")
            }}</span>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-4">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-hash"
                :label="
                  $t(resources.BookingCode.i18n) || resources.BookingCode.name
                "
                v-model="bookingDetail.bookingReference"
                v-validate="'required|min:3|max:20'"
                @blur="
                  bookingDetail.bookingReference = bookingDetail.bookingReference.replace(
                    /\s+/g,
                    ''
                  )
                "
                name="code"
              />
            </div>
            <span class="text-danger text-sm">{{
              errors.first("form-1.code")
            }}</span>
          </div>

          <div class="vx-col md:w-1/4 w-full mb-4">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-dollar-sign"
                type="number"
                :label="$t(resources.NetValue.i18n) || resources.NetValue.name"
                v-model="bookingDetail.value"
                @keyup="sumTotal()"
                name="netValue"
                v-validate="'required|decimal:2'"
              />
            </div>
            <span class="text-danger text-sm">{{
              errors.first("form-1.netValue")
            }}</span>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-4">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-dollar-sign"
                type="number"
                :label="
                  $t(resources.TaxesValue.i18n) || resources.TaxesValue.name
                "
                v-model="bookingDetail.tax"
                @keyup="sumTotal()"
                name="taxValue"
                v-validate="'required|decimal:2'"
              />
            </div>
            <span class="text-danger text-sm">{{
              errors.first("form-1.taxValue")
            }}</span>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-4">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-dollar-sign"
                type="number"
                :label="$t(resources.FeeValue.i18n) || resources.FeeValue.name"
                v-model="bookingDetail.fee"
                @keyup="sumTotal()"
                name="feeValue"
                v-validate="'required|decimal:2'"
              />
            </div>
            <span class="text-danger text-sm">{{
              errors.first("form-1.feeValue")
            }}</span>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-4">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                type="number"
                icon="icon-dollar-sign"
                :label="
                  $t(resources.MerchantValue.i18n) ||
                    resources.MerchantValue.name
                "
                v-model="bookingDetail.merchant"
                @keyup="sumTotal()"
                name="merchantValue"
                v-validate="'required|decimal:2'"
              />
            </div>
            <span class="text-danger text-sm">{{
              errors.first("form-1.merchantValue")
            }}</span>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-4">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-dollar-sign"
                icon-no-border
                type="number"
                :label="
                  $t(resources.AgentCommissionValue.i18n) ||
                    resources.AgentCommissionValue.name
                "
                v-model="bookingDetail.agentCommission"
                v-on:change="sumTotal()"
              />
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-4">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-dollar-sign"
                disabled
                :label="
                  $t(resources.BookingValue.i18n) || resources.BookingValue.name
                "
                v-model="bookingDetail.total"
              />
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-4">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-dollar-sign"
                :label="
                  $t(resources.FeeCustomized.i18n) ||
                    resources.FeeCustomized.name
                "
                v-model="bookingDetail.feeCustomized"
                v-on:change="sumTotal()"
                name="feeCustomized"
                v-validate="'required|decimal:2'"
              />
            </div>
            <span class="text-danger text-sm">{{
              errors.first("form-1.feeCustomized")
            }}</span>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-4">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-dollar-sign"
                disabled
                :label="
                  $t(resources.TotalValue.i18n) || resources.TotalValue.name
                "
                :value="bookingDetail.totalComplete"
              />
            </div>
          </div>
        </div>
      </vx-card>
    </form>

    <vx-card class="mb-4">
      <vs-tabs>
        <vs-tab
          :label="$t(resources.Passengers.i18n) || resources.Passengers.name"
        >
          <form class="vx-row" data-vv-scope="form-2">
            <div class="vx-col md:w-1/4 w-full">
              <label class="vs-input--label">{{
                $t(resources.DocumentType.i18n) || resources.DocumentType.name
              }}</label>
              <v-select
                name="passengerDocumentType"
                label="name"
                v-model="passengerModel.documentType"
                v-validate="'required'"
                :clearable="false"
                :options="identifiacionTypeList"
                :reduce="item => item.id"
              />
              <span class="text-danger text-sm">{{
                errors.first("form-2.passengerDocumentType")
              }}</span>
            </div>
            <div class="vx-col md:w-1/4 w-full">
              <vs-input
                name="passengerDocumentNumber"
                :label="
                  $t(resources.DocumentNumber.i18n) ||
                    resources.DocumentNumber.name
                "
                v-model="passengerModel.document"
                v-validate="'required|alpha_num'"
                class="w-full"
                icon-pack="feather"
                icon="icon-hash"
                maxlength="30"
              />
              <span class="text-danger text-sm">{{
                errors.first("form-2.passengerDocumentNumber")
              }}</span>
            </div>
            <div class="vx-col md:w-1/4 w-full">
              <vs-input
                name="passengerName"
                :label="$t(resources.Name.i18n) || resources.Name.name"
                v-model="passengerModel.name"
                v-validate="'required|min:3|alpha_spaces'"
                class="w-full"
                icon-pack="feather"
                icon="icon-user"
                maxlength="100"
              />
              <span class="text-danger text-sm">{{
                errors.first("form-2.passengerName")
              }}</span>
            </div>
            <div class="vx-col md:w-1/4 w-full">
              <vs-input
                name="passengerLastname"
                :label="$t(resources.LastName.i18n) || resources.LastName.name"
                v-model="passengerModel.lastName"
                v-validate="'required|min:3|alpha_spaces'"
                class="w-full"
                icon-pack="feather"
                icon="icon-user"
                maxlength="100"
              />
              <span class="text-danger text-sm">{{
                errors.first("form-2.passengerLastname")
              }}</span>
            </div>
            <div class="vx-col md:w-1/4 w-full">
              <label class="vs-input--label">{{
                $t(resources.PassengerType.i18n) || resources.PassengerType.name
              }}</label>
              <v-select
                name="passengerType"
                label="text"
                v-model="passengerModel.passengerTypeId"
                v-validate="'required'"
                :clearable="false"
                :options="passengerType"
                :reduce="item => item.value"
              />
              <span class="text-danger text-sm">{{
                errors.first("form-2.passengerType")
              }}</span>
            </div>
            <div class="vx-col md:w-1/4 w-full">
              <label class="vs-input--label"
                >{{
                  $t(resources.BirthDate.i18n) || resources.BirthDate.name
                }}
                (YYYY-MM-DD)</label
              >
              <vs-input
                name="passengerBirthDate"
                v-model="passengerModel.birthDate"
                v-validate="{ required: true, regex: /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/ }"
                class="w-full"
                icon-pack="feather"
                icon="icon-calendar"
                placeholder="YYYY-MM-DD"
              />
              <span class="text-danger text-sm">{{
                errors.first("form-2.passengerBirthDate")
              }}</span>
            </div>
            <div class="vx-col md:w-1/4 w-full">
              <label class="vs-input--label">{{
                $t(resources.ExpirationDatePassport.i18n) ||
                  resources.ExpirationDatePassport.name
              }} (YYYY-MM-DD)</label>
              <vs-input
                name="passengerPassport"
                v-model="passengerModel.passportExpiration"
                v-validate="{ required: false, regex: /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/ }"
                class="w-full"
                icon-pack="feather"
                icon="icon-calendar"
                maxlength="10"
                placeholder="YYYY-MM-DD"
              />
              <span class="text-danger text-sm">{{
                errors.first("form-2.passengerPassport")
              }}</span>
            </div>
            <div class="vx-col md:w-1/4 w-full">
              <label class="vs-input--label">{{
                $t(resources.Gender.i18n) || resources.Gender.name
              }}</label>
              <v-select
                name="passengerGender"
                label="text"
                v-model="passengerModel.genderId"
                v-validate="'required'"
                :clearable="false"
                :options="genderType"
                :reduce="item => item.value"
              />
              <span class="text-danger text-sm">{{
                errors.first("form-2.passengerGender")
              }}</span>
            </div>
            <div
              class="vx-col md:w-1/4 w-full"
              v-if="bookingDetail.productId == database.products.AIR"
            >
              <label class="vs-input--label">{{
                $t(resources.Country.i18n) || resources.Country.name
              }}</label>
              <v-select
                name="passengerNationality"
                label="name"
                v-model="passengerModel.nationalityId"
                v-validate="'required'"
                :clearable="false"
                :options="countryPassenger"
                :reduce="country => country.id"
              />
              <span class="text-danger text-sm">{{
                errors.first("form-2.passengerNationality")
              }}</span>
            </div>
            <div
              class="vx-col md:w-1/4 w-full"
              v-if="
                bookingDetail.productId == database.products.AIR &&
                  countryOperation == 'USA'
              "
            >
              <vs-input
                name="passengerEmail"
                :label="$t(resources.Email.i18n) || resources.Email.name"
                v-model="passengerModel.email"
                v-validate="'required|email'"
                class="w-full"
                icon-pack="feather"
                icon="icon-mail"
                maxlength="150"
              />
              <span class="text-danger text-sm">{{
                errors.first("form-2.passengerEmail")
              }}</span>
            </div>
            <div
              class="vx-col md:w-1/4 w-full"
              v-if="bookingDetail.productId == database.products.AIR"
            >
              <label class="vs-input--label">{{
                $t(resources.Indicative.i18n) || resources.Indicative.name
              }}</label>
              <v-select
                name="passengerIndicative"
                label="country"
                v-model="passengerModel.indicative"
                v-validate="'required'"
                :clearable="false"
                :options="countryList"
                :reduce="country => country.id"
              />
              <span class="text-danger text-sm">{{
                errors.first("form-2.passengerIndicative")
              }}</span>
            </div>
            <div
              class="vx-col md:w-1/4 w-full"
              v-if="bookingDetail.productId == database.products.AIR"
            >
              <vs-input
                name="passengerPhone"
                :label="
                  $t(resources.TelephoneNumber.i18n) ||
                    resources.TelephoneNumber.name
                "
                v-model="passengerModel.phone"
                v-validate="'required|min:8'"
                class="w-full"
                icon-pack="feather"
                icon="icon-hash"
                maxlength="20"
              />
              <span class="text-danger text-sm">{{
                errors.first("form-2.passengerPhone")
              }}</span>
            </div>
            <div class="vx-col w-full md:w-1/4 mb-2 mt-5">
              <vs-button color="primary" type="filled" @click="savePassenger">
                {{ $t(resources.Add.i18n) || resources.Add.name }}
              </vs-button>
            </div>
          </form>
          <vs-divider class="mb-2">
            <h4 class="font-semibold mb-base">
              {{ $t(resources.Passengers.i18n) || resources.Passengers.name }}
            </h4>
          </vs-divider>
          <vs-table :data="passengerDto">
            <template slot="thead">
              <vs-th>{{
                $t(resources.Name.i18n) || resources.Name.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.LastName.i18n) || resources.LastName.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.DocumentNumber.i18n) ||
                  resources.DocumentNumber.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.BirthDate.i18n) || resources.BirthDate.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.ExpirationDatePassport.i18n) ||
                  resources.ExpirationDatePassport.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.Options.i18n) || resources.Options.name
              }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].name">{{
                  data[indextr].name
                }}</vs-td>
                <vs-td :data="data[indextr].lastName">{{
                  data[indextr].lastName
                }}</vs-td>
                <vs-td :data="data[indextr].document">{{
                  data[indextr].document
                }}</vs-td>
                <vs-td :data="data[indextr].birthDate">{{
                  data[indextr].birthDate
                }}</vs-td>
                <vs-td :data="data[indextr].ExpirationDatePassport">{{
                  data[indextr].ExpirationDatePassport
                }}</vs-td>
                <vs-td>
                  <a @click="deletePassenger(data[indextr])">
                    <feather-icon
                      icon="XIcon"
                      svgClasses="fill-current text-danger"
                      class="w-5 h-5 mr-1"
                    ></feather-icon>
                  </a>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vs-tab>
        <vs-tab
          :label="$t(resources.Itinerary.i18n) || resources.Itinerary.name"
        >
          <form class="vx-row mb-4" data-vv-scope="form-3">
            <!-- If Hotel -->
            <div
              class="vx-col md:w-1/4 w-full mb-2"
              v-if="
                bookingDetail.productId == 1 || bookingDetail.productId == 9
              "
            >
              <label>{{
                $t(resources.Hotels.i18n) || resources.Hotels.name
              }}</label>
              <v-select
                id="searchOrigin"
                @keyup.native="getHotelByName('Origin')"
                v-model="hotelId"
                :clearable="true"
                :options="hotelList"
                :placeholder="
                  $t(resources.Select.i18n) || resources.Select.name
                "
                :reduce="x => x.id"
                label="name"
              />
            </div>

            <!-- If Package -->
            <div
              class="vx-col md:w-1/4 w-full mb-2"
              v-if="bookingDetail.productId == database.products.PAQ"
            >
              <label>{{
                $t(resources.Packages.i18n) || resources.Packages.name
              }}</label>
              <v-select
                v-model="packageId"
                :clearable="true"
                :options="packageList"
                :placeholder="
                  $t(resources.Select.i18n) || resources.Select.name
                "
                :reduce="x => x.packageId"
                label="nameEs"
              />
            </div>

            <!-- If Insurance -->
            <div
              class="vx-col md:w-1/2 w-full mb-2"
              v-if="bookingDetail.productId == 5"
            >
              <div class="vx-row mb-4">
                <div class="vx-col md:w-1/2 w-full mb-2">
                  <label class="vs-input--label">{{
                    $t(resources.Origin.i18n) || resources.Origin.name
                  }}</label>
                  <v-select
                    v-model="origin"
                    :clearable="false"
                    :options="originOption"
                    :reduce="x => x.id"
                    label="name"
                  />
                </div>
                <div class="vx-col md:w-1/2 w-full mb-2">
                  <label class="vs-input--label">{{
                    $t(resources.Destination.i18n) || resources.Destination.name
                  }}</label>
                  <v-select
                    v-model="destination"
                    :clearable="false"
                    :options="destinationOption"
                    :reduce="x => x.id"
                    label="name"
                  />
                </div>
              </div>
            </div>

            <!-- Si es cualquier otro producto -->
            <div
              class="vx-col md:w-1/2 w-full mb-2"
              v-if="
                bookingDetail.productId !== 1 &&
                  bookingDetail.productId !== 4 &&
                  bookingDetail.productId !== 5 &&
                  bookingDetail.productId !== 9
              "
            >
              <div class="vx-row mb-4">
                <div class="vx-col md:w-1/2 w-full mb-2">
                  <label>{{
                    $t(resources.Origin.i18n) || resources.Origin.name
                  }}</label>
                  <v-select
                    id="searchOrigin"
                    @keyup.native="getCityByName('Origin')"
                    v-model="origin"
                    :clearable="true"
                    :options="originOption"
                    :placeholder="
                      $t(resources.WriteOrigin.i18n) ||
                        resources.WriteOrigin.name
                    "
                    :reduce="origin => origin.id"
                    label="name"
                  />
                </div>
                <div class="vx-col md:w-1/2 w-full mb-2">
                  <label>{{
                    $t(resources.Destination.i18n) || resources.Destination.name
                  }}</label>
                  <v-select
                    id="searchDestination"
                    @keyup.native="getCityByName('Destination')"
                    v-model="destination"
                    :clearable="true"
                    :options="destinationOption"
                    :placeholder="
                      $t(resources.WriteDestination.i18n) ||
                        resources.WriteDestination.name
                    "
                    :reduce="destination => destination.id"
                    label="name"
                  />
                </div>
              </div>
            </div>

            <div class="vx-col md:w-1/4 w-full mb-2">
              <label>{{
                $t(resources.Supplier.i18n) || resources.Supplier.name
              }}</label>
              <v-select
                v-model="gds"
                :clearable="true"
                :options="gdsOption"
                :placeholder="
                  $t(resources.Select.i18n) || resources.Select.name
                "
                :reduce="gds => gds.id"
                label="name"
              />
            </div>
            <div class="vx-col md:w-1/4 w-full mb-2">
              <label>{{
                $t(resources.DepartureDate.i18n) || resources.DepartureDate.name
              }}</label>
              <flat-pickr
                class="vx-col w-full"
                :config="configdateTimePicker"
                v-model="departureDate"
                placeholder="yyyy-MM-dd Hh:mm"
              />
            </div>
            <div class="vx-col md:w-1/4 w-full mb-2">
              <label>{{
                $t(resources.ArrivalDate.i18n) || resources.ArrivalDate.name
              }}</label>
              <flat-pickr
                class="vx-col w-full"
                :config="configdateTimePicker"
                v-model="arrivalDate"
                placeholder="yyyy-MM-dd Hh:mm"
              />
            </div>

            <div
              class="vx-col w-full md:w-3/4 w-full mb-2"
              v-if="bookingDetail.productId == 2"
            >
              <div class="vx-row mb-4">
                <div class="vx-col w-full md:w-1/3 w-full mb-2">
                  <label>{{
                    $t(resources.LenderServices.i18n) ||
                      resources.LenderServices.name
                  }}</label>
                  <v-select
                    id="searchLender"
                    @keyup.native="getAirlineByText()"
                    v-model="lender"
                    :clearable="true"
                    :options="lenderOption"
                    :placeholder="
                      $t(resources.Select.i18n) || resources.Select.name
                    "
                    :reduce="lender => lender.id"
                    label="name"
                  />
                </div>
                <div class="vx-col w-full md:w-1/3 w-full mb-2">
                  <label>{{
                    $t(resources.Class.i18n) || resources.Class.name
                  }}</label>
                  <vs-input
                    class="w-full"
                    icon-pack="feather"
                    maxlength="2"
                    v-model="flightClass"
                    v-validate="'alpha'"
                  />
                </div>
                <div class="vx-col w-full md:w-1/3 w-full mb-2">
                  <label>{{
                    $t(resources.FlightNumber.i18n) ||
                      resources.FlightNumber.name
                  }}</label>
                  <vs-input
                    class="w-full"
                    icon-pack="feather"
                    maxlength="10"
                    v-model="flightNumber"
                    v-validate="'numeric'"
                  />
                </div>
              </div>
            </div>

            <div class="vx-col w-full mt-4">
              <vs-button color="primary" type="filled" @click="saveItinerary">
                {{ $t(resources.Add.i18n) || resources.Add.name }}
              </vs-button>
            </div>
          </form>
          <vs-table :data="itineraryDto">
            <template slot="thead">
              <vs-th>{{
                $t(resources.Origin.i18n) || resources.Origin.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.Destination.i18n) || resources.Destination.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.StartDate.i18n) || resources.StartDate.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.EndDate.i18n) || resources.EndDate.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.SupplierName.i18n) || resources.SupplierName.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.Options.i18n) || resources.Options.name
              }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].origin">{{
                  data[indextr].origin
                }}</vs-td>
                <vs-td :data="data[indextr].destination">{{
                  data[indextr].destination
                }}</vs-td>
                <vs-td :data="data[indextr].departureDate">{{
                  data[indextr].departureDate
                }}</vs-td>

                <vs-td :data="data[indextr].arrivalDate">{{
                  data[indextr].arrivalDate
                }}</vs-td>
                <vs-td :data="data[indextr].supplierName">{{
                  data[indextr].supplierName
                }}</vs-td>
                <td>
                  <a @click="deleteItinerary(data[indextr])">
                    <feather-icon
                      icon="XIcon"
                      svgClasses="fill-current text-danger"
                      class="w-5 h-5 mr-1"
                    ></feather-icon>
                  </a>
                </td>
              </vs-tr>
            </template>
          </vs-table>
        </vs-tab>
      </vs-tabs>
      <vs-row vs-type="flex" vs-justify="center" class="mb-2">
        <div class="vx-col w-full md:w-1/4 mb-2 mt-5 text-center">
          <vs-button
            color="success"
            type="filled"
            @click.prevent="validForm"
            size="large"
          >
            {{ $t(resources.Create.i18n) || resources.Create.name }}
            {{ $t(resources.Booking.i18n) || resources.Booking.name }}
          </vs-button>
        </div>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import axios from "axios";
import vSelect from "vue-select";
import utilities from "@/assets/resources/utilities.json";
import database from "@/assets/resources/enums/database.json";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  components: {
    vSelect,
    Datepicker,
    flatPickr
  },
  data() {
    return {
      alerts: alerts,
      resources: resources,
      utilities: utilities,
      database: database,
      language: this.$i18n.locale,
      languages: lang,
      configdateTimePicker: {
        enableTime: true,
        dateFormat: "d-M-Y H:i",
        minDate: "today"
      },
      iboCode: "",
      emailUser: "",
      booking: {},
      phoneTypes: [
        { id: 1, type: "Local" },
        { id: 2, type: "Mobile" }
      ],
      discountInOption: [
        { text: "Sin clasificar", value: "0" },
        { text: "Pago total", value: "1" },
        { text: "Fee administrativo", value: "2" },
        { text: "Merchant", value: "3" },
        { text: "Fee administrativo + Merchant", value: "4" },
        { text: "Valor neto", value: "5" },
        { text: "Valor neto + Fee administrativo", value: "6" },
        { text: "Valor neto + Merchant", value: "7" },
        { text: "Impuestos", value: "8" }
      ],
      iboList: [],
      countryList: [],
      accountNumber: "",
      contactName: "",
      bookingDetail: {
        value: 0,
        tax: 0,
        fee: 0,
        merchant: 0,
        total: 0,
        feeCustomized: 0,
        totalComplete: 0,
        discount: 0,
        agentCommission: 0,
        discountIn: "0",
        bookingSourceId: 21
      },
      currencyType: [],
      productType: [],
      passengerDto: [],
      itineraryDto: [],
      countryOption: [],
      countryOperation: "",
      sourceOption: [],
      customers: [],
      origin: "",
      originOption: [],
      destination: "",
      destinationOption: [],
      gds: "",
      gdsOption: [],
      lender: "",
      lenderOption: [],
      departureDate: "",
      arrivalDate: "",
      flightClass: "",
      flightNumber: "0",
      datetimeEmpty: "",
      passengerModel: {},
      identifiacionTypeList: [],
      genderType: [
        { text: "Femenino", value: 1 },
        { text: "Masculino", value: 2 },
        { text: "Otro", value: 3 }
      ],
      passengerType: [
        { text: "Adulto", value: 1 },
        { text: "Bebé con asiento", value: 4 },
        { text: "Bebé en brazos", value: 5 },
        { text: "Bebé acompañado", value: 6 },
        { text: "Niño acompañado", value: 7 },
        { text: "Niño no acompañado", value: 8 }
      ],
      countryPassenger: [],
      hotelList: [],
      hotelId: 0,
      packageList: [],
      packageId: 0
    };
  },
  async created() {
    this.$vs.loading();
    await this.getCountry();
    await this.getCurrency();
    await this.getProductType();
    await this.getCountryOperation();
    await this.getGds();
    await this.getIdentifiacionType();
    this.$vs.loading.close();
  },
  methods: {
    async deletePassenger(data) {
      if (this.passengerDto.find(x => x.document == data.document)) {
        let _index = this.passengerDto.findIndex(
          x => x.document == data.document
        );
        if (_index > -1) this.passengerDto.splice(_index, 1);
      }
    },

    async deleteItinerary(data) {
      if (this.itineraryDto.find(x => x.document == data.document)) {
        let _index = this.itineraryDto.findIndex(
          x => x.document == data.document
        );
        if (_index > -1) this.itineraryDto.splice(_index, 1);
      }
    },

    async savePassenger() {
      this.$validator.validateAll("form-2").then(async result => {
        if (result) {
          let _passengerDto = {
            passengerTypeId: this.passengerModel.passengerTypeId,
            name: this.passengerModel.name,
            lastName: this.passengerModel.lastName,
            documentTypeId: this.passengerModel.documentType,
            document: this.passengerModel.document,
            nationality: this.passengerModel.nationalityId,
            birthDate: this.passengerModel.birthDate,
            ExpirationDatePassport: this.passengerModel.passportExpiration,
            gender: this.passengerModel.genderId,
            email: this.passengerModel.email,
            phone:
              this.passengerModel.indicative != null
                ? this.passengerModel.indicative + this.passengerModel.phone
                : null
          };

          this.passengerDto.push(_passengerDto);
          this.passengerModel = {};
        } else {
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: this.$i18n.t("MsgRequiredField"),
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      });
    },

    async getIdentifiacionType() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }IdentificationType/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) this.identifiacionTypeList = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getItineraryByProduct(item) {
      if (item == this.database.products.SEG) {
        await this.getCountryInsurance();
      } else if (item == this.database.products.PAQ) {
        await this.getPackage();
      } else {
        this.originOption = [];
        this.destinationOption = [];
      }
    },

    async getCountry() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Country/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.countryList = result.data.map(x => ({
              country: `${x.name} (${x.indicative})`,
              id: x.iso3
            }));
            this.countryPassenger = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getIboByText() {
      let getElementId = document.getElementById("iboCode");
      let text = getElementId.__vue__.search;

      if (text.length >= 4) {
        await axios({
          method: "GET",
          url: `${
            process.env.VUE_APP_OVAPI
          }Contact/getByText/${this.$i18n.locale.toUpperCase()}/${text}`,
          headers: {
            "content-type": "application/json",
            Authorization: `bearer ${localStorage.getItem("loggedIn")}`
          }
        }).then(
          result => {
            if (!result.data) {
              this.$vs.notify({
                title: this.$i18n.t("Alert"),
                text: this.$i18n.t("MsgNotFoundInfo"),
                color: "warning",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
              this.sponsorList = [];
            } else {
              this.iboList = result.data;
            }
          },
          error => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError") + "<br/>" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        );
      }
    },

    async getCustomers(iboCode) {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Contact/Customer/${this.$i18n.locale.toUpperCase()}/${iboCode}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            let _contacts = [];

            result.data.forEach(element => {
              element["fullName"] = `${element.name} ${element.lastName}`;
              _contacts.push(element);
            });

            this.customers = _contacts;
            this.booking.iboCode = this.iboCode;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getProductType() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Product/ByType/${this.$i18n.locale.toUpperCase()}/1`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) this.productType = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCurrency() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Currency/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) this.currencyType = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    getContact() {
      let _contact = this.customers.find(x => x.id == this.booking.contactId);

      this.contactName = `${_contact.name} ${_contact.lastName}`;
      this.emailUser = _contact.email;
      this.booking.emergencyName = `${_contact.name} ${_contact.lastName}`;
      this.booking.emailVoucher = _contact.email;
      this.booking.emergencyPhone = _contact.phone;
    },

    validForm() {
      this.$validator.validateAll("form-1").then(async result => {
        if (result) {
          this.$vs.loading();

          await this.create();

          this.$vs.loading.close();
        } else {
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: this.$i18n.t("MsgRequiredField"),
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      });
    },

    async create() {
      var contactInfo = JSON.parse(localStorage.getItem("userInfo"));

      this.bookingDetail["currencyId"] = this.currencyType.find(
        x => x.code == this.bookingDetail.currencyCode
      ).id;
      this.bookingDetail["language"] = this.$i18n.locale.toUpperCase();
      this.bookingDetail["createdBy"] = contactInfo.email;
      this.bookingDetail["supplierId"] = this.itineraryDto[0].supplierId;
      this.bookingDetail["passengers"] = this.passengerDto;
      this.bookingDetail["itinerary"] = this.itineraryDto;

      let _data = {
        contactId: this.booking.contactId,
        itaCode: this.booking.iboCode,
        countryCode: this.countryOperation,
        emailVoucher: this.booking.emailVoucher,
        confirmEmailVoucher: this.booking.emailVoucher,
        emergencyName: this.booking.emergencyName,
        emergencyCountryCode: this.booking.emergencyCountryCode,
        emergencyPhoneType: this.booking.emergencyPhoneType,
        emergencyPhone: this.booking.emergencyPhone,
        applicationId: this.utilities.application.FOC,
        language: this.$i18n.locale.toUpperCase(),
        createdBy: contactInfo.email,
        bookingDetail: [this.bookingDetail]
      };

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Booking/BookingManual`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        },
        data: _data
      }).then(
        result => {
          if (result.data) {
            this.$router.push({
              name: "bookingDetail",
              params: {
                bookingId: result.data
              }
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCountryOperation() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }CountryOperation/GetPccCountry/${this.$i18n.locale.toUpperCase()}/1`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.warningNotify();
            this.countryOption = [];
          } else {
            this.countryOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCityByName(from) {
      let getElementId;
      if (from == "Origin")
        getElementId = document.getElementById("searchOrigin");
      else getElementId = document.getElementById("searchDestination");

      let textSearch = getElementId.__vue__.search;

      if (textSearch.length >= 3) {
        this.$vs.loading();

        let _url = `${
          process.env.VUE_APP_APIVT
        }City/GetByText/${this.$i18n.locale.toUpperCase()}/${textSearch}`;

        if (this.bookingDetail.productId == 2)
          _url = `${
            process.env.VUE_APP_APIVT
          }Airport/GetByText/${this.$i18n.locale.toUpperCase()}/${textSearch}`;

        await axios({
          method: "GET",
          url: _url,
          headers: {
            "content-type": "application/json",
            Authorization: `bearer ${localStorage.getItem("tokenVT")}`
          }
        }).then(
          result => {
            if (!result.data) {
              this.$vs.notify({
                title: this.$i18n.t("Alert"),
                text: this.$i18n.t("MsgNotFoundInfo"),
                color: "warning",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
              this.originOption = [];
              this.destinationOption = [];
            } else {
              if (from == "Origin") {
                this.originOption = result.data;
              } else this.destinationOption = result.data;
            }
          },
          error => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError") + "<br/>" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.originOption = [];
            this.destinationOption = [];
          }
        );
        this.$vs.loading.close();
      }
    },

    async getHotelByName() {
      let getElementId;
      getElementId = document.getElementById("searchOrigin");

      let textSearch = getElementId.__vue__.search;

      if (textSearch.length >= 3) {
        this.$vs.loading();
        await axios({
          method: "GET",
          url: `${
            process.env.VUE_APP_APIVT
          }Hotel/GetByName/${this.$i18n.locale.toUpperCase()}/${textSearch}`,
          headers: {
            "content-type": "application/json",
            Authorization: `bearer ${localStorage.getItem("tokenVT")}`
          }
        }).then(
          result => {
            if (!result.data) {
              this.$vs.notify({
                title: this.$i18n.t("Alert"),
                text: this.$i18n.t("MsgNotFoundInfo"),
                color: "warning",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
              this.hotelList = [];
            } else {
              this.hotelList = result.data;
            }
          },
          error => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError") + "<br/>" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.originOption = [];
            this.destinationOption = [];
          }
        );
        this.$vs.loading.close();
      }
    },

    async getGds() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Supplier`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.gdsOption = [];
          } else {
            this.gdsOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getAirlineByText() {
      let getElementId = document.getElementById("searchLender");
      let textSearch = getElementId.__vue__.search;

      if (textSearch.length == 3) {
        await axios({
          method: "GET",
          url: `${process.env.VUE_APP_APIVT}Airline/GetByText/${textSearch}`,
          headers: {
            "content-type": "application/json",
            Authorization: `bearer ${localStorage.getItem("tokenVT")}`
          }
        }).then(
          result => {
            if (result.data == 0) {
              this.$vs.notify({
                title: this.$i18n.t("Alert"),
                text: this.$i18n.t("MsgNotFoundInfo"),
                color: "warning",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
              this.lenderOption = [];
            } else {
              this.lenderOption = result.data;
            }
          },
          error => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError") + "<br/>" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.lenderOption = [];
          }
        );
      }
    },

    async getPackage() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Package/ListByFilters`,
        data: {
          searchKeys: { active: "true" },
          page: 1,
          rows: 500,
          language: "EN",
          orderBy: "FechaCreacion-DESC"
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.packageList = [];
          } else {
            this.packageList = result.data.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
          this.originOption = [];
          this.destinationOption = [];
        }
      );
    },

    async getCountryInsurance() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Country/GetForInsurance/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.originOption = [];
            this.destinationOption = [];
          } else {
            this.originOption = result.data;
            this.destinationOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    successNotify() {
      this.$vs.notify({
        title: this.$i18n.t("Success"),
        text: this.$i18n.t("MsgSaved"),
        color: "success",
        iconPack: "feather",
        position: "top-right",
        icon: "icon-x-circle"
      });
    },

    warningNotify() {
      this.$vs.notify({
        title: this.$i18n.t("Alert"),
        text: this.$i18n.t("MsgNotFoundInfo"),
        color: "warning",
        iconPack: "feather",
        position: "top-right",
        icon: "icon-x-circle"
      });
    },

    sumTotal() {
      parseFloat(
        (this.bookingDetail.total =
          parseFloat(this.bookingDetail.value) +
          parseFloat(this.bookingDetail.tax) +
          parseFloat(this.bookingDetail.fee) +
          parseFloat(this.bookingDetail.merchant) +
          parseFloat(this.bookingDetail.agentCommission))
      ).toFixed(2);

      parseFloat(
        (this.bookingDetail.totalComplete =
          parseFloat(this.bookingDetail.total) +
          parseFloat(this.bookingDetail.feeCustomized) +
          parseFloat(this.bookingDetail.discount))
      ).toFixed(2);
    },

    async saveItinerary() {
      let _departureDate = moment(this.departureDate);
      let _arrivalDate = moment(this.arrivalDate);
      let _dateCompare = moment().add(-1, "d");

      if (
        _departureDate.isBefore(_dateCompare) ||
        _arrivalDate.isBefore(_dateCompare)
      ) {
        this.$vs.notify({
          title: this.$i18n.t("Alert"),
          text: this.$i18n.t("MsgItineraryBeforeNow"),
          color: "warning",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-x-circle"
        });
        return;
      }
      if (_departureDate.isAfter(_arrivalDate)) {
        this.$vs.notify({
          title: this.$i18n.t("Alert"),
          text: this.$i18n.t("MsgItineraryAfterArrival"),
          color: "warning",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-x-circle"
        });
        return;
      }

      let _originCode = "";
      let _destinationCode = "";
      let _originId = "";
      let _destinationId = "";
      let _entity = 0;
      let _identifier = 0;
      let _originName = "";
      let _destinationName = "";

      if (this.bookingDetail.productId == this.database.products.HOT) {
        let _hotel = this.hotelList.find(x => x.id == this.hotelId);
        _entity = this.database.entity.hotel;
        _identifier = this.hotelId;
        _originId = _hotel.cityId;
        _destinationId = _hotel.cityId;
        _originCode = _hotel.cityCode;
        _destinationCode = _hotel.cityCode;
        _originName = _hotel.cityName;
        _destinationName = _hotel.cityName;
      } else if (this.bookingDetail.productId == this.database.products.PAQ) {
        let _package = this.packageList.find(
          x => x.packageId == this.packageId
        );
        _entity = this.database.entity.packages;
        _identifier = this.packageId;
        _originId = _package.destinationId;
        _destinationId = _package.destinationId;
        _originName = _package.cityName;
        _destinationName = _package.cityName;
      } else if (this.bookingDetail.productId == this.database.products.SEG) {
        _entity = this.database.entity.insurance;
        let _ini = this.originOption.find(x => x.id == this.origin);
        let _fin = this.destinationOption.find(x => x.id == this.destination);

        _originId = _ini.capitalId;
        _destinationId = _fin.capitalId;
        _originName = `${_ini.capitalName}, ${_ini.name} (${_ini.iso3})`;
        _destinationName = `${_fin.capitalName}, ${_fin.name} (${_fin.iso3})`;
      } else {
        _originId = this.origin;
        _destinationId = this.destination;
        let _ini = this.originOption.find(x => x.id == this.origin);
        let _fin = this.destinationOption.find(x => x.id == this.destination);

        _originCode = _ini.code;
        _destinationCode = _fin.code;
        _originName = _ini.name;
        _destinationName = _fin.name;
      }

      if (
        this.bookingDetail.productId == this.database.products.AIR ||
        this.bookingDetail.productId == this.database.products.BAG
      ) {
        _entity = this.database.entity.airline;
        _identifier = this.lender;
      }

      let _itineraryDto = {
        originId: _originId,
        originCode: _originCode,
        destinationId: _destinationId,
        destinationCode: _destinationCode,
        origin: _originName,
        destination: _destinationName,
        departureDate: _departureDate.format("YYYY-MM-DD HH:mm"),
        arrivalDate: _arrivalDate.format("YYYY-MM-DD HH:mm"),
        identifier: _identifier,
        entityId: _entity,
        supplierId: this.gds,
        supplierName: this.gdsOption.find(x => x.id == this.gds).name,
        flightNumber: this.flightNumber,
        classService: this.flightClass
      };
      this.itineraryDto.push(_itineraryDto);
    }
  }
};
</script>
